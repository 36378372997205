<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                Create Air Destination
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <form>
                    <v-autocomplete v-model="editedData.from"
                                    label="FROM"
                                    required
                                    :items="selectData.destinations"
                                    item-text="value"
                                    item-value="id"
                                    :error-messages="fromErrors"
                                    @input="$v.editedData.from.$touch()"
                                    @blur="$v.editedData.from.$touch()"
                    ></v-autocomplete>

                    <v-autocomplete v-model="editedData.to"
                                    label="TO"
                                    required
                                    :items="selectData.destinations"
                                    item-text="value"
                                    item-value="id"
                                    :error-messages="toErrors"
                                    @input="$v.editedData.to.$touch()"
                                    @blur="$v.editedData.to.$touch()"
                    ></v-autocomplete>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success", 'serviceID'],
        computed: {
            fromErrors() {
                const errors = [];
                if (!this.$v.editedData.from.$dirty) return errors;
                !this.$v.editedData.from.required &&
                errors.push("Sector Type is required");
                return errors;
            },
            toErrors() {
                const errors = [];
                if (!this.$v.editedData.to.$dirty) return errors;
                !this.$v.editedData.to.required &&
                errors.push("Sector Code is required.");
                return errors;
            }
        },
        data() {
            return {
                selectData: {
                    destinations: [],
                    selectedValue: null
                },
                province: [],
                editedData: {
                    from: "",
                    to: "",
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                from: {required},
                to: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                this.updated = false;
                let serviceData = await axios.get(`Aviation/GetAirDestinationByIDAsync/${this.serviceID}`);
                this.editedData = serviceData.data
                const destinations = await axios.get('StaticValues/DDLCommonStaticValuesListAsync/100')
                this.selectData.destinations = destinations.data
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        AirDestinationID: this.serviceID,
                        from: this.editedData.from,
                        to: this.editedData.to
                    };
                    axios.post("Aviation/UpdateAirDestinationAsync", param).then(response => {
                        if (response.status == 200) {
                            this.$v.$reset();
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Destination updated succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()

                    });
                    this.submitStatus = "PENDING";
                    setTimeout(() => {
                        this.submitStatus = "OK";
                    }, 500);
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.from = "";
                this.editedData.to = "";
            }
        }
    };
</script>

<style scoped>
</style>